@import "node_modules/@styles/ent-styles/mixins/breakpoints";

.upload-file {
  .lfg-color {
    color: #822433;
  }
  .modal-popup {
    background: #edf6f8;
    border: 20px solid #fff;
    padding: 15px;
    .accordion {
        @include mobile() {
            margin: 0 0 20px 0;
            border: 1px solid #dcdee0;
        }
    }
    .fa-circle-custom {
        position: relative;
    }
    .fa-plus-custom {
        color: #fff;
        position: absolute;
        left
        : 4.3px;
        top: 4px;
        font-size: 8px;
    }
    &__header {
        border: none;
        margin-bottom: 20px;
    }
    .accordion, h6, .file-name-cont{
        background: #fff;
    }
    .accordion__content {
        margin: 0px 20px 18px 75px;
        ul {
            list-style: disc;
        }
    }
    .modal-popup__header {
        @include mobile() {
            margin-bottom: 0;
        }
    }
    h3 {
        text-transform: none;
    }
    p {
        font-size: 12px;
        line-height: normal;
        padding-top: 10px;
    }
    P {
        padding: 10px 0 0 0;
    }
    .add-file {
      display: flex;
      justify-content: flex-start;
        @include mobile() {
            display: flex;
            align-items: center;
        }
    }
    .divider {
        padding: 0 15px;
        font-size: 30px;
        font-weight: 100;
        color: #616b72;
        vertical-align: sub;
        @include mobile() {
          vertical-align: baseline;
        }
    }
    .remove-all {
        img {
            height: 25px;
            opacity: .15;
        }
        em {
            font-size: 18px;
            position: relative;
            top: -7px;
            padding: 5px;
            display: inline-block;
            padding-bottom: 0;
            @include mobile() {
              top: -4px;
            }
        }
        background: transparent !important;
        @include mobile() {
            text-align: right !important;
            display: contents;
        }
        &.active {
            img {
                opacity: 1;
            }
            em:hover {
                text-decoration: underline !important;
            }
          &:focus {
            em {
              text-decoration: underline !important;
            }
          }
        }
    }
    span {
        color: #37424A;
        padding-left: 10px;
    }
    p, .file-name-cont {
        font-style: italic;
        color: #37424A;
    }
    h6 {
        padding: 5px 20px 0px;
        font-weight: 500;
        color: #37424A;
    }
    .file-name-cont {
        padding: 10px 0 10px 15px;
        @include mobile() {
            text-align: center;
        }
    }
    .file-selected-header {
        display: flex;
        @include mobile() {
            flex-direction: column;
        }
        & + .file-selected-header {
            border-top: 1px solid #d4d4d4;
        }
    }
    .widget-cont {
        background: #fff;
        margin: 20px 0;
        .widget-header {
        // display: flex;
            @include mobile() {
                flex-direction: column;
            }
            & + .widget-header {
                border-top: 1px solid #d4d4d4;
            }
        }
    }
    .file-name {
        flex-grow: 3;
        flex-shrink: 1;
        flex-basis: 0;
    }
    .action {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        @include mobile() {
            text-align: center !important;
            padding-bottom: 10px;
        }
    }
    .remove-all-files {
        display: flex;
        flex-direction: row-reverse;
        padding-bottom: 5px;
        width: 100%;
    }
    .btn--link {
        text-align: left;
        padding-left: 0;
        color: #0d5da6;
        font-style: normal;
        font-weight: 400;
    }
    .btn-tray__left{
        .btn--link {
            @include mobile() {
                text-align: center;
                flex: 1.2;
            }
        }
    }
    .btn--link-disabled {
      color: #616b72;

      pointer-events: none;
      cursor: default;
      background: #fff;
      flex: 1.2;
      &.action {
        padding-top: 10px;
      }
      @include mobile() {
          text-align: center;
          padding-top: 0;
          &.action {
            padding-top: 0;
          }
      }
    }
    .btn--custom {
        background: #036d9b;
        padding: 14px 32px;
        &:disabled {
            background-color: #d8d8d8;
        }
    }
    &__footer {
        border: none;
        padding: 0;
    }
  }
  progress {
    background: #ddd;
    border-radius: 10px;
    width: 100%;
  }
  progress::-webkit-progress-bar {
    background: #ddd;
    border-radius: 10px;
    width: 100%;
  }
  progress::-webkit-progress-value {
    background: #822433;
    border-radius: 10px;
  }
  progress::-moz-progress-bar {
    background: #ddd;
    border-radius: 10px;
    width: 100%;
  }
  .add-file {
    margin-bottom: 15px;
    a {
      vertical-align: middle;
      flex: 1;
    }
  }
  .no-margin {
    margin: 0;
  }

  .padding20{
    padding: 20px;
  }

  .font-weight200 {
    font-weight: 200;
  }

}

/* You can add global styles to this file, and also import other style files */
@import "node_modules/@styles/ent-styles/core/colors";
@import "node_modules/@styles/ent-styles/core/config";
@import "node_modules/@styles/ent-styles/mixins/breakpoints";
@import "node_modules/@styles/ent-styles/mixins/forms";
@import "node_modules/@styles/ent-styles/mixins/fonts";
@import "node_modules/@styles/ent-styles/mixins/typography";
@import "node_modules/@styles/ent-styles/mixins/scaffolding";

//components
@import "app/contract/policy-details/policy-details.component.scss";
@import "app/contract/case-tracker/case-tracker.component.scss";
@import "app/contract/outstanding-requirements/outstanding-requirements.component.scss";
@import "app/contract/upload-file/upload-file.component.scss";
@import "app/contract/download-file/download-file.component.scss";

body,
* {
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-style: normal;

  :focus {
    outline: 1px dotted grey;
  }
}

.no-margin-top {
    margin-top: 20px;
}

a {
  cursor: pointer;
}

.alert-message__content {
  word-break: break-word;
}

.modal-popup__focus-trap {
  display: none;
}

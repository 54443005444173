@import "node_modules/@styles/ent-styles/mixins/breakpoints";

h2 {
  margin-bottom: 20px;
}

.article-component-cont > header > h1,
.article-component-cont > header > h2,
.article-component-cont > header > h3,
.article-component-cont > header > h4 {
  margin-top: 0;
  text-transform: none;
}

.article-component-cont > header {
  border-bottom: 1px solid #dcdee0;
  h3, h4 {
    font-weight: 500;
    text-transform: uppercase;
    .fas, .far {
      margin-right: 10px;
      color: #ce6b27;
    }
  }
}

.article-component-cont {
  margin: 5px 0px 20px 0px;
  border-width: 2px;
  border-color: #dcdee0;
  padding: 15px;

  .article-content {
    margin-top: 20px;
  }

  .ent-custom-table-cont {
    @include mobile() {
      overflow: auto;
    }
  }

  .ent-table-primary__th {
    font-weight: 500;
  }

  .ent-table-primary__td {
    word-break: break-word;
  }
}

// client info

.client-info {
  .ent-table-primary {
    .ent-table-primary__th {
      font-weight: 500;
      background: none;
      border-right: 0;
      color: #37424a;

      &.align-right {
        text-align: right;
      }
    }

    .ent-table-primary__tr {
      background-color: #edf6f8;
    }

    .ent-table-primary__td {
      border-bottom: 5px solid #fff;
      border-right: 5px solid #fff;
    }

    .ent-table-primary__tr > * {
      box-sizing: border-box;
    }

    .ent-table-primary__td:nth-child(1) {
      min-width:225px;
      border-left: 0;
    }

    .ent-table-primary__td:nth-child(2) {
      min-width:300px;
    }

    .ent-table-primary__td:nth-child(3) {
      min-width:250px;
    }
    
    .ent-table-primary__td:nth-child(4) {
      min-width:220px;
    }
  }
}

// requirement

.requirement {
  .resolve-btn {
    padding: 10px 15px;
    border-radius: 5px;
    height: 38px;
    width: 230px;
    text-transform: none;

    &:hover {
      padding: 9px 14px;

      &:focus {
        padding: 9px 14px;
      }
    }
    &:focus {
      padding: 9px 14px;
    }
  }

  .fileDownload {
    color: #ce6b27;
    font-size: 15px;
    padding-left: 10px;
  }

  .btn-tray__right button:nth-child(1) {
    @include mobile() {
      margin-top: 20px;
    }

  }
  .accordion__content {
    margin: 10px 20px 18px 20px;

    @include mobile() {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .ent-table-primary {
    width: 100%;
  }

  //outstanding-requirements-table
  .outstanding-requirements-table .ent-table-primary__th:nth-child(1) {
    width: 10%;
  }

  .outstanding-requirements-table .ent-table-primary__th:nth-child(2) {
    width: 10%;
  }
  // .outstanding-requirements-table .ent-table-primary__th:nth-child(3) {
  //   width: 50%;
  // }

  .outstanding-requirements-table .ent-table-primary__th:nth-child(4) {
    width: 5%;
  }

  .outstanding-requirements-table .ent-table-primary__th:nth-child(5) {
    width: 10%;
  }

  .outstanding-requirements-table .ent-table-primary__th:nth-child(6) {
    width: 15%;
  }

  //outstanding-requirements-table with action and comment column hidden
  .outstanding-requirements-table.hide-col .ent-table-primary__th:nth-child(1) {
    width: 15%;
  }
  .outstanding-requirements-table.hide-col .ent-table-primary__th:nth-child(2) {
    width: 60%;
  }
  .outstanding-requirements-table.hide-col .ent-table-primary__th:nth-child(3) {
    width: 10%;
  }
  .outstanding-requirements-table.hide-col .ent-table-primary__th:nth-child(4) {
    width: 15%;
  }

  //completed-requirements-table
  .completed-requirements-table .ent-table-primary__th:nth-child(1) {
    width: 15%;
  }

  .completed-requirements-table .ent-table-primary__th:nth-child(3) {
    width: 10%;
  }

  .completed-requirements-table .ent-table-primary__th:nth-child(4) {
    width: 15%;
  }

  .completed-requirements-table .ent-table-primary__th:nth-child(5) {
    width: 15%;
  }

  .alert-message__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .accordion__header {
    @include mobile() {
      padding: 15px 0px 13px 0px;
    }
  }
}

// agent info

.agent-info {
  &__grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px 0 20px 0;
  }

  &__row {
    padding-left: 30px;
    border-right: 1px solid #d7d7d7;
    flex: auto;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border: none;
    }
  }

  .ent-table-primary {
    .ent-table-primary__td{
      padding-right: 60px;
    }
  }

  .pagination-container {
    display: none;
  }

  .fa-caret-down, .fa-caret-up {
    font-size: 20px;
    margin-left: 5px;
  }

  .ent-account-list-page {
    margin-bottom: 20px;
  }
}

.contract-and-rate-lock-info {
  display: flex;

  @include mobile() {
    flex-direction: column;
  }
}

.contract-info {
  margin-top: 0px !important;
  .ent-table-primary {
    width: 100%;

    .ent-table-primary__thead {
      display: none;
    }
    .ent-group__header {
      display: none;
    }

    .ent-table-primary__td:first-child {
      max-width: 100px;
    }

    .ent-table-primary__td:last-child {
      font-weight: 500;
    }
  }
}

app-contract-information {
  flex: 1;
}

app-rate-lock-information {
  flex:1;
  display: flex;
  margin-left: 20px;
  .article-component-cont{
    width: 100%;
  }
  @include mobile() {
    margin-left: 0px;
  }
}

.disclaimer {
  margin-top: 20px;
}

.funds-received {
  .funds-received-table {
    width: 500px;
  }
}

.ent-table-primary {
  a{
    font-size: 12px;
    line-height: normal;
  }
}

.screen-reader-only {
  border: 0 none;
  clip: rect(0px,0px,0px,0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.source-of-funds-table .ent-table-primary__th:nth-child(1) {
  width: 10%;
}

.source-of-funds-table .ent-table-primary__th:nth-child(2) {
  width: 12%;
}

.source-of-funds-table .ent-table-primary__th:nth-child(5) {
  width: 12%;
}

.contract-info sup {
  font-size: 10px;
}

.contract-info .sup-sm {
  font-size: 8px;
}

.contract-info em {
  font-style: italic;
}

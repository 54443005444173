@import "node_modules/@styles/ent-styles/mixins/breakpoints";

.download-file {
  .lfg-color {
    color: #822433;
  }
  .modal-popup {
    background: #edf6f8;
    border: 20px solid #fff;
    padding: 15px;
    .accordion {
        @include mobile() {
            margin: 0 0 20px 0;
            border: 1px solid #dcdee0;
        }
    }
    .fa-circle-custom {
        position: relative;
    }
    .fa-plus-custom {
        color: #fff;
        position: absolute;
        left
        : 4.3px;
        top: 4px;
        font-size: 8px;
    }
    &__header {
        border: none;
    }
    .accordion, h4, .file-name-cont{
        background: #fff;
    }
    .accordion__content {
        margin: 0px 20px 18px 75px;
        ul {
            list-style: disc;
        }
    }
    .modal-popup__header {
        @include mobile() {
            margin-bottom: 0;
        }
    }
    h3 {
        text-transform: none;
    }

    .add-file {
      display: flex;
      justify-content: flex-start;
        @include mobile() {
            display: flex;
            align-items: center;
        }
    }
    .divider {
        padding: 0 15px;
        font-size: 30px;
        font-weight: 100;
        color: #616b72;
        vertical-align: sub;
        @include mobile() {
          vertical-align: baseline;
        }
    }
    .remove-all {
        img {
            height: 25px;
            opacity: .15;
        }
        em {
            font-size: 18px;
            position: relative;
            top: -7px;
            padding: 5px;
            display: inline-block;
            padding-bottom: 0;
            @include mobile() {
              top: -4px;
            }
        }
        background: transparent !important;
        @include mobile() {
            text-align: right !important;
            display: contents;
        }
        &.active {
            img {
                opacity: 1;
            }
            em:hover {
                text-decoration: underline !important;
            }
          &:focus {
            em {
              text-decoration: underline !important;
            }
          }
        }
    }
    span {
        color: #37424A;
        padding-left: 10px;
    }
    .file-name-cont {
        font-style: italic;
        color: #37424A;
        padding: 7px 0 7px 15px;
        @include mobile() {
            text-align: center;
        }
    }

    h4 {
        margin: 0;
        padding: 5px 20px 0px;
        font-weight: 500;
        color: #37424A;
    }
    .file-selected-header {
        display: flex;
        min-width: 450px;

        & + .file-selected-header {
            border-top: 1px solid #d4d4d4;
        }

        &:last-child {
            border-bottom: 1px solid #d4d4d4;
        }
    }
    .widget-cont {
        background: #fff;
        margin: 20px 0;
        .widget-header {
        // display: flex;
            @include mobile() {
                flex-direction: column;
            }
            & + .widget-header {
                border-top: 1px solid #d4d4d4;
            }
        }
    }
    .file-name {
        flex-grow: 3;
        flex-shrink: 1;
        flex-basis: 0;

        @include mobile() {
          flex-grow: 2;
          flex-shrink: 1;
          flex-basis: 0;
        }
    }
    .action {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        @include mobile() {
            text-align: center;
            padding-bottom: 10px;
        }
    }
    .remove-all-files {
        display: flex;
        flex-direction: row-reverse;
        padding-bottom: 5px;
        width: 100%;
    }
    .btn--link {
        padding-left: 0;
        color: #0d5da6;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
    }
    .btn-tray__left{
        .btn--link {
            @include mobile() {
                text-align: center;
                flex: 1.2;
            }
        }
    }
    .btn--link-disabled {
      color: #616b72;
      pointer-events: none;
      cursor: default;
      background: #fff;
      &.action {
        padding-top: 10px;
      }
      @include mobile() {
          text-align: center;
          padding-top: 0;
          &.action {
            padding-top: 0;
          }
      }
    }
    .btn--custom {
        background: #036d9b;
        padding: 14px 32px;
        &:disabled {
            background-color: #d8d8d8;
        }
    }
    &__footer {
        border: none;
        padding: 0;
    }
  }
  progress {
    background: #ddd;
    border-radius: 10px;
    width: 100%;
  }
  progress::-webkit-progress-bar {
    background: #ddd;
    border-radius: 10px;
    width: 100%;
  }
  progress::-webkit-progress-value {
    background: #822433;
    border-radius: 10px;
  }
  progress::-moz-progress-bar {
    background: #ddd;
    border-radius: 10px;
    width: 100%;
  }
  .add-file {
    margin-bottom: 15px;
    a {
      vertical-align: middle;
      flex: 1;
    }
  }
  .no-margin {
    margin: 0;
  }

  .padding20{
    padding: 20px;
  }

  .font-weight200 {
    font-weight: 200;
  }

  .top-header .file-name-cont {
    color: #fff;
    background: #616b72;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-right: 1px solid #dcdee0;
  }

  .file-selected-header .file-name {
    border-right: 1px solid #dcdee0;
    border-left: 1px solid #dcdee0;
    word-break: break-word;
  }

  .file-selected-header .action {
    border-right: 1px solid #dcdee0;
    padding-left: 0 !important;
    text-align: center;

    &:first-child {
      border-left: 1px solid #dcdee0;
    }
  }

  .modal-popup__body {
    border: 1px solid #dcdee0;
    padding: 10px;
    background: #fff;
  }

  .download-file-disc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile() {
      flex-direction: column;
      margin-bottom: 20px;
      align-items: flex-start;
    }

    p {
      flex: 1;
      padding-right: 20px;

      @include mobile() {
        margin-bottom: 10px;
      }
    }

    ent-left-button-tray {

      @include mobile() {
        width: 100%;
      }
    }

    .btn {
      margin-right: 0;
      padding: 8px 20px;

      &:hover, &:focus {
        padding: 7px 19px;
      }
    }
  }

  .ent-checkbox label:before, .ent-checkbox label:after {
    top: -15px;
  }

  input[type=checkbox], .inp-radio-hide {
    z-index: 9999;
    width: 20px;
    height: 20px;
  }

  .no-padding-left {
     padding-left: 0 !important;
  }

  .center-align {
    text-align: center !important;
  }

  .no-border-left {
    border-left: 0 !important;
  }

  .no-border-right {
    border-right: 0 !important;
  }

  .download-icon-size {
    font-size: 25px;
  }

  .top-header h4 {
    padding: 10px 0 10px 15px;
  }

  .line-height {
    line-height: 1;
  }

  .previously-downloaded .fa-check-circle {
    color: #008E2A;
    font-size: 20px;
    vertical-align: bottom;
  }

  .modal-popup__body {
    .table-cont {
      overflow: auto;
    }
  }

}

@import "node_modules/@styles/ent-styles/mixins/breakpoints";

.outstanding-requirements {
  .info {
    background-color: #edf6f8;
    padding: 15px;
    margin: 20px 0;
  }

  .no-margin {
    margin: 0;
  }

  .no-padding {
    padding: 0;
  }

  .no-border {
    border: 0;
  }

  .border-bottom {
    border-top: 0;
    border-right: 0;
    border-bottom: 3px solid #bfbfbf;
    border-left: 0;
  }

  .padding-left {
    padding: 0 0 0 20px;
  }

  .padding-bottom {
    padding: 0 0 20px 0;
  }

  .padding-top {
    padding: 20px 0 0 0;
  }

  .requirement-form {
    header {
      display: flex;
      border: 0;
      p {
        flex: 1;
        padding: 0 0 10px 10px;
        word-break: break-word;
      }
      @include mobile() {
        flex-direction: column;
      }
    }
    textarea {
      resize: none;
    }
    .pagination-container {
      display: none;
    }
    .btn-tray__left {
      margin-top: 10px;
    }
    .btn {
      padding: 9px 20px;
      font-size: 15px;
    }
    .btn--whitebg-border:hover, .btn--whitebg-border:focus {
      padding: 8px 19px;
    }
    .view-links {
      padding-top: 10px;
    }
    .far {
      font-size: 18px;
    }

    table {
      width: 100%;
      margin-top: 40px;
      position: relative;
      z-index: 1;
    }
    .ent-table-primary__th-sort-btn {
      pointer-events: none;
    }
    .ent-table-primary__td {
      word-break: break-word;
    }
    .ent-table-primary__th:nth-child(1) {
      width: 15%;
    }
    .ent-table-primary__th:nth-child(2) {
      width: 20%;
    }
    .form--control {
      position: relative;
    }
    textarea:focus + label {
      top: -11px;
      font-size: 12px;
      text-decoration: none;
      font-style: normal;
      background-color: #fff;
      padding: 0px 2px 0px 2px;
      color: #37424a;
    }
    textarea.inp-txt--err {
      border-color: #a00;
      background: url(/assets/images/ex.png) calc(100% - 8px) / 25px auto no-repeat #fff2f4;
      padding-right: 40px;
    }

    .hr-line {
      width: 100%;
      height: 20px;
      border-style: solid;
      border-width: medium;
      border-color: #fff;
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      border-bottom: 3px solid #bfbfbf;
    }

    .hr-line:after,
    .hr-line:before {
      content: "";
      border-style: solid;
      border-width: 20px;
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 20px;
    }

    .hr-line:before {
      border-color: #bfbfbf transparent transparent;
    }

    .hr-line:after {
      margin-top: -4px;
      border-color: #fff transparent transparent;
    }
  }

  .bg-color {
    background-color: #f2f4f6;
  }

  .fa-arrow-alt-circle-left {
    margin-right: 5px;
    font-size: 20px;
    vertical-align: text-bottom;
  }

  .fade-out {
    animation: fadeOut ease 5s;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  .char-info {
    padding-top: 20px;
    display: block;
    margin-bottom: -20px;
  }
}

@import "node_modules/@styles/ent-styles/mixins/breakpoints";

// case tracker
.article-content {
  position: relative;
  
  @include tablet() {
    &:has(:nth-child(6)) {
      overflow: auto;
    }
  }
}
.case-tracker {
  display: flex;
  overflow: auto;

  @include tablet() {
    &:has(:nth-child(6)) {
      min-width: 999px;
    }
  }
  @include mobile() {
    flex-direction: column;
  }
  &::before {
    content: "";
  }

  .status-date {
    font-size: 14px;
    color: #616b72;
    margin-left: -15px;
    margin-right: 5px;
    text-wrap: nowrap;
    @include desktop() {
      animation: showDate 2s 0s;
    }
  }

  @keyframes showDate {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
  }
}

.tracker {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  @include tablet() {
    flex: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  a {
    font-size: 14px;
    margin-left: 5px;
  }
  + .tracker {
    @include mobile() {
      margin-top: 10px;
    }
  }
}

.tracker:nth-child(2) {
  .tracker-step {
    animation-delay: 0s;
    &:before {
      animation-delay: 0s;
    }
    &:after {
      animation-delay: 0.7s;
    }
  }
}

.tracker:nth-child(3) {
  .tracker-step {
    animation-delay: 0.7s;
    &:before {
      animation-delay: 0.7s;
    }
    &:after {
      animation-delay: 1.4s;
    }
  }
}

.tracker:nth-child(4) {
  .tracker-step {
    animation-delay: 1.4s;
    &:before {
      animation-delay: 1.4s;
    }
    &:after {
      animation-delay: 2.1s;
    }
  }
}

.tracker:nth-child(5) {
  .tracker-step {
    animation-delay: 2.1s;
    &:before {
      animation-delay: 2.1s;
    }
    &:after {
      animation-delay: 2.8s;
    }
  }
}


.tracker:nth-child(6) {
  .tracker-step {
    animation-delay: 2.8s;
    &:before {
      animation-delay: 2.8s;
    }
    &:after {
      animation-delay: 3.5s;
    }
  }
}

.tracker:nth-child(7) {
  .tracker-step {
    animation-delay: 3.5s;
    &:before {
      animation-delay: 3.5s;
    }
    &:after {
      animation-delay: 4.2s;
    }
  }
}

.tracker:nth-child(8) {
  .tracker-step {
    animation-delay: 4.2s;
    &:before {
      animation-delay: 4.2s;
    }
    &:after {
      animation-delay: 4.9s;
    }
  }
}

.tracker-step {
  display: flex;
  margin-right: 15px;
  height: 33px;
  margin-bottom: 5px;
  background: #dcdee0;
  color: #37424a;
  font-size: 14px;
  text-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  
  @include tablet() {
    min-width: max-content;
  }

  .fas,
  .far {
    margin-right: 5px;
    font-size: 16px;
    line-height: 15px;
  }
  &::before {
    content: "";
    border-left: 12px solid transparent;
    border-top: 17px solid #dcdee0;
    border-bottom: 16px solid #dcdee0;
    border-right: 0;
    position: absolute;
    left: -11px;
    top: 0;
    bottom: 0;
  }
  &::after {
    content: "";
    border-left: 12px solid #dcdee0;
    border-top: 18px solid transparent;
    border-bottom: 17px solid transparent;
    border-right: 0;
    position: absolute;
    right: -11px;
  }
  &.completed {
    color: #fff;
    text-wrap: nowrap;
    @include above(750px) {
      background: linear-gradient(to right, #248476 50%, #dcdee0 0);
      background-size: 200% 100%;
      background-position: right;
      animation: animate 0.7s linear 0s forwards;
    }
    @include mobile() {
      background: #248476;
    }
    &::before {
      @include mobile() {
        border-top: 17px solid #248476;
        border-bottom: 16px solid #248476;
      }
      @include above(750px) {
        animation: animateCompleteBefore 0s steps(100000, end) 0s forwards;
      }
    }
    &::after {
      @include mobile() {
        border-left: 12px solid #248476;
      }
      @include above(750px) {
        border-left: 12px solid #dcdee0;
        animation: animateCompleteAfter 0s steps(100000, end) 0s forwards;
      }
    }
  }
  &.inprogress {
    text-wrap: nowrap;
    @include above(750px) {
      background: linear-gradient(to right, #195da6 50%, #dcdee0 0);
      background-size: 200% 100%;
      background-position: right;
      animation: animate 0.7s linear 0s forwards;
    }
    @include mobile() {
      background: #195da6;
    }
    color: #fff;
    &::before {
      @include mobile() {
        border-top: 17px solid #195da6;
        border-bottom: 16px solid #195da6;
      }
      @include above(750px) {
        animation: animateInprogresBefore 0s steps(1000000, end) 0s forwards;
      }
    }
    &::after {
      @include mobile() {
        border-left: 12px solid #195da6;
      }
      @include above(750px) {
        border-left: 12px solid #dcdee0;
        animation: animateInprogresAfter 0s steps(1000000, end) 0s forwards;
      }
    }
  }
  @keyframes animate {
    100% {
      background-position: left;
      opacity: 1;
    }
  }
  @keyframes animateInprogresAfter {
    0% { border-left: 12px solid #dcdee0; }
    25% { border-left: 12px solid #dcdee0; }
    50% { border-left: 12px solid #dcdee0; }
    75% { border-left: 12px solid #195da6; }
    100% { border-left: 12px solid #195da6; }
  }
  @keyframes animateCompleteAfter {
    0% { border-left: 12px solid #dcdee0; }
    25% { border-left: 12px solid #dcdee0; }
    50% { border-left: 12px solid #dcdee0; }
    75% { border-left: 12px solid #248476; }
    100% { border-left: 12px solid #248476; }
  }
  @keyframes animateInprogresBefore {
    0% { border-top: 17px solid #dcdee0;
      border-bottom: 16px solid #dcdee0; }
    100% { border-top: 17px solid #195da6;
      border-bottom: 16px solid #195da6; }
  }
  @keyframes animateCompleteBefore {
    0% { border-top: 17px solid #dcdee0;
      border-bottom: 16px solid #dcdee0; }
    100% { border-top: 17px solid #248476;
      border-bottom: 16px solid #248476; }
  }
}

.case-tracker .tracker:first-child .tracker-step::before {
  content: "";
  border: none;
}
.case-tracker .tracker:last-child .tracker-step {
  margin-right: 12px;
  @include mobile() {
    margin-right: 17px;
  }
  &::after {
    content: "";
    border-left: 12px solid #dcdee0;
    border-top: 17px solid #dcdee0;
    border-bottom: 16px solid #dcdee0;
    top: 0;
    bottom: 0;
  }

  &.inprogress::after {
    @include above(750px){
      border: 0;
    }
    @include mobile(){
      border-left: 12px solid #195da6;
      border-top: 17px solid #195da6;
      border-bottom: 16px solid #195da6;
    }
  }

  &.completed::after {
    @include above(750px){
      border: 0;
    }
    @include mobile(){
      border-left: 12px solid #248476;
      border-top: 17px solid #248476;
      border-bottom: 16px solid #248476;
    }
  }
}

.mobile-view {
  display: none;
  @include mobile() {
    display: inline-block;
  }
}

.no-mobile-view {
  @include mobile() {
    display: none;
  }
}
